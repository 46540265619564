import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiService } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import WebsiteRedesignServices from '~/views/Services/WebsiteRedesign/WebsiteRedesign.view'

const WebsiteRedesignServicesPage = () => <WebsiteRedesignServices />

export default WebsiteRedesignServicesPage

export const query = graphql`
  query {
    strapiService(page_name: { eq: "Website Redesign Services" }) {
      ...MetaTagInformationServices
    }
  }
`

export const Head = ({ data }: QueryResult<StrapiService>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiService,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/services/ui-ux-design/website-redesign-services/"
      isBreadcrumbs
      secondLevel="Services"
      secondLevelPath="/services/"
      thirdLevel="UI/UX Design Services"
      thirdLevelPath="/services/ui-ux-design/"
      current="Website Redesign Services"
      currentPath="/services/ui-ux-design/website-redesign-services/"
    />
  )
}
